import { useOrganization } from "../hooks/useOrganization"
import { useRole } from "../hooks/useRole"

const Organization = () => {
  const orgnization = useOrganization()
  const role = useRole()
  return (
    <>
      <h3 style={{margin: 0}}>{orgnization}</h3>
      <h5 style={{margin: 0}}>{role}</h5>
    </>
  )
}

export default Organization