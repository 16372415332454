import { Container, Typography } from "@material-ui/core"
import { Link } from "@reach/router"
import { useFacilities } from "../hooks/useFacilities"
import IFacility from "../interfaces/IFacility"
import S from "./styled"

export const FacilityList = () => {
  const { data, error, isFetching } = useFacilities()
  
  if(isFetching || error) {
    return <S.LoadingIndicator>---</S.LoadingIndicator>
  }
  

  if(data && data.length === 0){
    return <S.PageMenu><h3 style={{textAlign: 'center'}}>Det finns inga anläggningar</h3></S.PageMenu>
  }

  return (
    <>
    <S.PageMenu>
      <strong>Anläggningar: </strong>
      {data && data.sort((a, b) => {
        if( a.name > b.name ) return 1;
        if( a.name < b.name ) return -1;
        return 0
      }).map(({id, name, facility_installation_date, facility_product, facility_type, facility_volume, facility_maxvolume}:IFacility) => <Link key={id} to={String(id)}>
      <Container>
        <Typography variant={"subtitle1"}>{name}</Typography>
        <Typography variant={"subtitle2"} style={{background: '#f7f7f7'}}>Driftsvolym:{facility_maxvolume || 0}L, Aktuell volym:{facility_volume}L</Typography>
      </Container>
    </Link>)}
    </S.PageMenu>
    </>
  )
}

export default FacilityList