import { Link, RouteComponentProps } from "@reach/router"
import S from "../../components/styled"
import Header from "../../components/Header"

export const OrderPage = (props: RouteComponentProps) => {

  return (
    <S.BaseApp2>
      <Header/>

      <S.Navigation >
        <S.Button2 onClick={() => window.location.href="https://envirostripp.se/produkt-kategori/produkter/"}>Webbshop</S.Button2>
        <S.Button2 onClick={() => window.location.href="mailto:ruben@ingridgopa.se"}>Kontakta beställningsansvarig</S.Button2>
        
      </S.Navigation>
    </S.BaseApp2>
  )
}
export default OrderPage