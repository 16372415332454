import axios from "axios"

export const login = async (username: string, password: string) => {
  
  console.log('Login')
  return await axios({
    url: 'https://envirostripp.se/wp-json/jwt-auth/v1/token',
    method: 'POST',
    data: {
      username: username,
      password: password
    }
  }).then((res: any) => {
    console.log(res)
    if(res.data.token) {
      localStorage.setItem('user', JSON.stringify(res.data))
    }
    return  res.data
  })
}

export const logout = () => {
  localStorage.removeItem('user')
}

const authApi = {
  login,
  logout
}

export default authApi