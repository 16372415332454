import { Link, RouteComponentProps } from "@reach/router"
import axios from "axios"
import { useQuery, useQueryClient } from "react-query"
import Login from "../components/Login"
import Organization from "../components/Organization"
import S from "../components/styled"
import { useRole } from "../hooks/useRole"
import useStore from "../stores"

export const StartPage = (props: RouteComponentProps) => {

  const queryClient = useQueryClient()

  const signedIn = useStore(state => state.signedIn)
  const logout = useStore(state => state.logout)
  const role = useRole()

  if(!signedIn) {
    return (
      <S.BaseApp>
         <S.Logo/>
         <Login/>
      </S.BaseApp>
    )
  }

  return (
   <S.BaseApp>
      <S.Logo/>
      <Organization/>
      <S.Navigation>
        <S.Button><Link to="analysis">Analys/Badkorrigering</Link></S.Button>
        <S.Button><Link to="logger">Nivåjustering</Link></S.Button>
        <S.Button><Link to="facilities">Anläggningsinfo</Link></S.Button>
        <S.Button><Link to="history">Historik</Link></S.Button>
        {role !== 'Användare' && <S.Button><Link to="order">Beställning</Link></S.Button>}
        <S.Button><Link to="contact">Kontakt ESC</Link></S.Button>
        <S.Button onClick={(e) => {
          queryClient.invalidateQueries()
          logout()
        }}>Logga ut</S.Button>
      </S.Navigation>
   </S.BaseApp>
  )
}
export default StartPage