import create from 'zustand'
import authApi from '../services/auth'

interface IOldCalcResult {
  actualVolume: number,
  ph: number,
  function: number,
  temperature: number,
  vattenAnalys: number,
}

type GlobalState = {
  statusMessage: string,
  signedIn: boolean,
  login: (username: string, password: string) => void,
  logout: () => void,
  setSignedIn: () => void,
  setSignedOut: () => void,
  calcResult: number,
  calcResultType: string,
  setCalcResult: (calcInput: number) => void,
  oldCalc: IOldCalcResult,
  setOldCalc: (oldCalc: IOldCalcResult) => void,
  setCalcResultType: (calcInputType: string) => void,
  facilities: string[],
  products: string[]
}

const useStore = create<GlobalState>(set => ({
  // Auth states
  signedIn: false,
  statusMessage: '',
  login: async (username, password) => {
    try {
      await authApi.login(username, password)
      set({ statusMessage: ''})
      set({ signedIn: true})
    } catch(e) {
      set({ statusMessage: 'Fel användarnamn eller lösenord'})
      set({ signedIn: false})
    }
    
    
  },
  logout: () => {
    authApi.logout()
    set({ signedIn: false})
  },
  setSignedIn: () => set({ signedIn: true }),
  setSignedOut: () => set({ signedIn: false }),
  // Analyse
  calcResult: 0,
  calcResultType: '',
  oldCalc: { actualVolume: 0,function: 0, ph: 0, temperature: 0, vattenAnalys: 0},
  setOldCalc: (oldCalc) => set({oldCalc: oldCalc}),
  setCalcResult: (calcInput) => set({ calcResult: calcInput }),
  setCalcResultType: (calcTypeInput) => set({ calcResultType: calcTypeInput }),
  // Other states
  facilities: ['Spoltvätt', 'Dopptvätt'],
  products: ['STRIPP-QT']
}))

export default useStore