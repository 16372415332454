import { RouteComponentProps } from "@reach/router"
import FacilityList from "../../components/FacilityList"
import FacilityListWithButtons from "../../components/FacilityListWithButtons"
import Header from "../../components/Header"
import S from "../../components/styled"


export const AnalysisPage = (props: RouteComponentProps) => {

  return (
   <S.SubPage>
     <Header/>
     <div>
      <h3>Gör en Analys/Badkorrigering</h3>
      <p>Välj en anläggning för att genomföra en analys</p>
     </div>
     <FacilityListWithButtons/>
   </S.SubPage>
  )
}
export default AnalysisPage