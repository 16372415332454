import { RouteComponentProps } from "@reach/router"
import Header from "../../components/Header"
import S from "../../components/styled"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons'


export const ContactPage = (props: RouteComponentProps) => {

  return (
   <S.SubPage>
   <Header/>
   <S.SubPageHeader>
   <h2>EnviroStripp Chemicals AB</h2>
     </S.SubPageHeader>
   <S.Navigation >
     <S.ContactLink href="tel:+4631271211">
        <FontAwesomeIcon icon={faPhone} size="10x" />
     </S.ContactLink>
     <S.ContactLink href="mailto:info@envirostripp.se">
        <FontAwesomeIcon icon={faEnvelope} size="10x" />
    </S.ContactLink>
   </S.Navigation>
 </S.SubPage>
  )
}
export default ContactPage