import { Checkbox, Input, InputLabel } from "@material-ui/core";
import { TextField } from "@material-ui/core"
import { Link, RouteComponentProps } from "@reach/router"
import axios from "axios"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import Header from "../../components/Header"
import S from "../../components/styled"
import { useFacilitiy } from "../../hooks/useFacility"
import { fetchProduct } from "../../hooks/useProduct"
import IProduct from "../../interfaces/IProduct"
import { IUpdateFacilityDto, updateFacility } from "../../services/updateFacility"
import useStore from "../../stores"
import { formatDate } from "../../utils/formatDate";

interface CorrectionPageProps extends RouteComponentProps{
  facilityId?: number
}

export const CorrectionPage = (props: CorrectionPageProps) => {
  const queryClient = useQueryClient();
  const { data, error, isFetching } = useFacilitiy(props.facilityId || -1)
  const [product, setProduct] = useState<IProduct>();

  const [inWater, setInWater] = useState<number>()
  const [inProduct, setInProduct] = useState<number>()

  const [actualVolume, setActualVolume] = useState<number>()

  const facilities = useStore(state => state.facilities)
  const [selectedAdjustmentDate, setSelectedAdjustmentDate] = useState(
    formatDate(new Date())
  );

  const mutation = useMutation((facility: IUpdateFacilityDto) => updateFacility(facility), {
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries()
      window.history.back()
    },
  })

  useEffect(() => {
    const fetchProductEffect = async () => {
      if(data && data?.facility_product){
        let res = await fetchProduct(data.facility_product)
        setProduct(res)
      }
    }
    
    fetchProductEffect()
  }, [data])

  
  if(isFetching || error || !data || !product) {
    return <S.SubPage>
      <S.LoadingIndicator>---</S.LoadingIndicator>
    </S.SubPage>
  }
  
  const calculatedValue = () => Math.round(Number(actualVolume || 0) + Number(inWater) + Number(inProduct))

  return (
   <S.SubPage>
     <Header/>
     <S.SubPageTitleText>
      <h3 className="title">Anläggning - {data.name}</h3>
      <h3 className="data">Badkorrigering</h3>
      <em>Fyll i vatten och/eller produkt för att justera volym i tanken.</em>
     </S.SubPageTitleText>
     <S.PageMenu justifyContent={"start"} direction={"column"} style={{flexGrow: 1}}>
     <InputLabel>Badkorrigeringsdatum: {selectedAdjustmentDate}</InputLabel>
       <Input
          value={selectedAdjustmentDate}
          onChange={(e) =>
            setSelectedAdjustmentDate(
              formatDate(new Date(e.currentTarget.value))
            )
          }
          type="date"
        />
       <div style={{padding: '10px'}}>
        <em style={{display: 'inline', margin: 0}}>Aktuell volym: </em>
        <input onChange={(e) => setActualVolume(Number(e.currentTarget.value))} value={actualVolume} style={{background: 'none', margin: 0, padding:'4px'}} type="text"/>
       </div>
       <div style={{padding: '10px'}}>
        <em style={{display: 'inline', margin: 0}}>Driftsvolym (önskad volym): </em>
        <em>{data.facility_maxvolume}L</em>
       </div>
       <em>Vatten i liter</em>
       <TextField onChange={(e) => setInWater(Number(e.currentTarget.value))} value={inWater} type="text"/>
       <em>{product.name} i liter</em>
       <TextField onChange={(e) => setInProduct(Number(e.currentTarget.value))} value={inProduct} type="text"/>
       <div style={{padding: '10px'}}>
        <h3 style={{display: 'inline', margin: 0}}>Ny volym:</h3>
        <em>{calculatedValue()}L</em>
       </div>
       {Number(calculatedValue()) > data.facility_maxvolume && (
          <em style={{ textAlign: "center", color: "red" }}>
            Tillsättningen kommer resultera i att tanken spiller över.
          </em>
        )}
     </S.PageMenu>
     <S.Button onClick={async (e) => {
            await mutation.mutateAsync({
              facility_id: data.id,
              facility_volume: calculatedValue(),
              facility_loggingtype: 1,
              facility_updatedate: selectedAdjustmentDate, 
              facility_addwater: inWater, 
              facility_addproduct: inProduct,
            })
          }}>Bekräfta</S.Button>
   </S.SubPage>
  )
}
export default CorrectionPage