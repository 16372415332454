import axios from "axios"
import { useQuery } from "react-query"
import IProduct from '../interfaces/IProduct'


export const fetchProduct = async (id: number) => {
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }
  const { data } = await axios.get(`https://envirostripp.se/wp-json/envapp/v1/products/${id}`,{headers})
  console.log(data)
  if(!data || data === null) return {id: 0, name: ''} as IProduct
  return JSON.parse(data) as IProduct
}

export const useProduct = (id: number) => {

  return useQuery("product", async () => {
    let data = await fetchProduct(id);
    return data;
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  })
}