import { Link, RouteComponentProps } from "@reach/router"
import FacilityList from "../../components/FacilityList"
import Header from "../../components/Header"
import S from "../../components/styled"
import { useFacilities } from "../../hooks/useFacilities"

export const LoggingPage = (props: RouteComponentProps) => {

  return (
   <S.SubPage>
     <Header/>
     <h3>Tillsättning - Nivåjustering</h3>
     <FacilityList/>
   </S.SubPage>
  )
}
export default LoggingPage