import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from "@reach/router"
import Header from "../../components/Header"
import S from "../../components/styled"
import { useFacilitiy } from "../../hooks/useFacility"
import { Line } from 'react-chartjs-2';
import IFacility from '../../interfaces/IFacility';
import { useHistory } from '../../hooks/useHistory';
import Button from '@material-ui/core/Button';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { fetchProduct, useProduct } from "../../hooks/useProduct"
import IProduct from "../../interfaces/IProduct"
import { ListItem } from '@material-ui/core';

interface HistroyFacilityPageProps extends RouteComponentProps{
  facilityId?: number
}

const getLastnumber = 20;
function GettLast(indata:string[]|number[]){
  return indata.slice(-getLastnumber);
}

function SetFacilityMaxvolume(facilityId:number) {
  const { data } = useFacilitiy(facilityId || -1)
  return data != undefined && data.facility_maxvolume != undefined ? data.facility_maxvolume.toString() : "0" ; //kanse en bra lösning?
}
//axis colors and varibals 
const phColor= 'rgb(83 127 48)'
const tempcolor= '#813668'
const aktuellVolymColor= 'rgba(42, 115, 250, 1)'
const tilatvattenColor= 'rgba(47, 195, 228,1)'
const tilsatproduktColor= '#8FB22E'
const funkColor= 'rgb(187, 132, 87)'
const vattenanalysColor= '#1589A2'
const fontsize= 16
const fontweight= 'bold'

// set options
const phtemraturoptions = {
  scales: {
    PHvalu: {
      title: {
        display: true,
        text: 'Ph värde ',
        color: phColor,
        font: {
          size: fontsize,
          weight: fontweight,
        },
      },
      type: 'linear',
      position: 'left',
      ticks: {
        callback: function(value: string) {
          if(value =='0'){{return 'saknar värde'}}
        }
      },
    }, 
    temratur: {
      title: {
        display: true,
        text: 'Temperatur',
        color: tempcolor,//y axel text
        font: {
          size: fontsize,
          weight: fontweight,
        },
      },
      type: 'linear',
      position: 'right',
      ticks: {
        //color: tempcolor,//y axel 
        callback: function(value: string) {
          if(value =='0'){{return 'saknar värde'}}
            else{return value + '°';}
        }
      },
      scaleLabel: {
        display: true,
        labelString: 'Liter'
      }
    },
  },
};
const h20procuctOptions = {
  responsive: true,
  scales: {
    tilsataliter: {
      title: {
        display: true,
        text: 'Antal Tillsatta Liter',
        color: tilsatproduktColor,
        font: {
          size: fontsize,
          weight: fontweight,
        },
      },
      type: 'linear',
      position: 'left',
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        callback: function(value: string) {
          return value + 'L';
          if(value =='0'){{return 'saknar värde'}}
            else{return value + 'L';}
        }
      },gridLines: {
        drawBorder: false,
      },
    }, 
    aktuellVolym: {
      title: {
        display: true,
        text: 'Aktuell  Volym',
        color: aktuellVolymColor,
        font: {
          size: fontsize,
          weight: fontweight,
        },
      },
      type: 'linear',
      position: 'right',
      ticks: {
        beginAtZero: true,
        stepSize: 1,
                callback: function(value: string) {
          return value + 'L';
        }
      },gridLines: {
        drawBorder: false,
      },
    },
  },
};
const konsentOptions = {
  responsive: true,
  scales: {
      konsentrationId: {
        title: {
          display: true,
          text: 'Vattenanalys',
          color: vattenanalysColor,
          font: {
            size: fontsize,
            weight: fontweight,
          },
        },
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          callback: function(value: string) {
            if(value =='0'){{return'saknar värde'}}
            else{return value + ' ml';}
          }
        },gridLines: {
          drawBorder: false,
        },
      },
      Funktion: {
        title: {
          display: true,
          text: 'Funktion',
          color: funkColor,
          font: {
            size: fontsize,
            weight: fontweight,
          },
        },
        type: 'linear',
        position: 'right',
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          callback: function(value: string) {
            if (value =='3'){value='Bra'}
            if (value =='2'){value='OKEJ'}
            if (value =='1'){value='Dålig'}
            if (value =='0'){value='saknar värde'}
            return value;
          }
        },gridLines: {
          drawBorder: false,
        },
      },
    },
};

//data diagram varubuls 
const tension = 0.2;
const pointRadius = 7;
const borderWidth = 3;
//set Data
function setPHtemp(pHdata:number[], temperatureData:number[]) {
   const PHtemp = [{
    label: 'PH Värde',
    yAxisID: 'PHvalu',
    data: GettLast(pHdata),
    fill: false,
    backgroundColor: phColor,
    borderColor: phColor,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
    range:['2020-04-04', '20122-08-06']
  },{
    label: 'Temperatur',
    yAxisID: 'temratur',
    data: GettLast(temperatureData),
    fill: false,
    backgroundColor: tempcolor,
    borderColor: tempcolor,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
  }]; 
  return PHtemp;
}
function SettStatus(functionData:number[], consentrasionData:number[]) {
  const status = [{
    label: ['Vattenanalys - avläst värde i ml'],
    yAxisID: 'konsentrationId',
    data: GettLast(consentrasionData),
    fill: false,
    backgroundColor: vattenanalysColor,
    borderColor: vattenanalysColor ,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
    range:['2020-04-04', '20122-08-06']
  },{
    yAxisID: 'Funktion',
    label: 'Funktion',
    data: GettLast(functionData),
    fill: false,
    backgroundColor: funkColor ,
    borderColor: funkColor,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
  }];
  return status;
}
function Settillsat(actualVolumeData:number[],H2OData:number[],productData:number[], facilityMaxvolume:string) {
  const tilsatmede = [{
    label: 'Tilsatt H2O',
    data: GettLast(H2OData),
    yAxisID: 'tilsataliter',
    fill: false,
    backgroundColor: tilatvattenColor,
    borderColor: tilatvattenColor,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
  },{
    label: 'Tilsatt produkt',
    data: GettLast(productData),
    fill: false,
    yAxisID: 'tilsataliter',
    backgroundColor: tilsatproduktColor,
    borderColor: tilsatproduktColor,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
  },{
    label: 'Aktuell volym: (Driftsvolym '+ facilityMaxvolume + 'L)',
    yAxisID: 'aktuellVolym',
    data: GettLast(actualVolumeData),
    fill: false,
    backgroundColor: aktuellVolymColor,
    borderColor: aktuellVolymColor,
    //cubicInterpolationMode: 'monotone',
    tension: tension,
    pointRadius: pointRadius,
    borderWidth: borderWidth,
  }]; 
  return tilsatmede
}

const FacilityChart = ({fid, type}: {fid: number, type: number}) => {
  const {data, error, isFetching} = useHistory(fid)
  const facilityMaxvolume = SetFacilityMaxvolume(fid)
  const [product, setProduct] = useState<IProduct>();

  const [dataset, setDataset] = useState<any>()

  //initiate data 
  useEffect(() => {
    if(data){
      let pHdata:number[] = []
      let temperatureData:number[] = []
      let actualVolumeData:number[] = []
      let H2OData:number[] = []
      let productData:number[] = []
      let functionData:number[] = []
      let consentrasionData:number[] = []

      // old setup
      /*let labels = data.map((log, index) => {
        //pHdata.push(log.facility_ph || 0)
        //temperatureData.push(log.facility_temperature || 0)
        //actualVolumeData.push(log.facility_volume || 0)
        //H2OData.push(log.facility_addwater || 0)
        //productData.push(log.facility_addproduct || 0)
        //consentrasionData.push(log.facility_meassured || 0)
        //functionData.push(log.facility_function || 0)
        return `${log.facility_logdate}(#${index})`
      })*/

      //get data
      //get ph/temp
      let labelsPh:string[]=[]
      data.forEach((log, index) => {
        if(log.facility_ph != null || log.facility_temperature != null ){
        pHdata.push(log.facility_ph)
        temperatureData.push(log.facility_temperature)
        labelsPh.push(log.facility_logdate+'('+index+ ')')
        }
      });

      //get status
      let labelStatus:string[]=[]
      data.forEach((log, index) => {
        if((log.facility_function != null && log.facility_function != 0)|| log.facility_meassured != null ){
          functionData.push(log.facility_function)
          consentrasionData.push(log.facility_meassured || 0)
          labelStatus.push(log.facility_logdate+'('+index+ ')')
        }
      });


      console.log("data");
      console.log(data);

      //get medel
      let labelmedel:string[]=[]
      data.forEach((log, index) => {
        actualVolumeData.push(log.facility_volume || 0)
        H2OData.push(log.facility_addwater || 0)
        productData.push(log.facility_addproduct || 0)
        labelmedel.push(log.facility_logdate+'('+index+ ')')
      });

      //set Data
      const type2 = [{
          label: 'TILLSATT H2O',
          data: GettLast(H2OData),
          yAxisID: 'tilsataliter',
          fill: false,
          backgroundColor: ['rgba(47, 195, 228,0.397)'],
          borderColor: ['rgba(47, 195, 228, 1)',],
          pointRadius: 10,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
        },{
          label: 'TILLSATT produkt',
          data: GettLast(productData),
          fill: false,
          yAxisID: 'tilsataliter',
          backgroundColor: ['rgba(185, 215, 99,0.5)',],
          borderColor: ['rgb(185, 215, 99)',],
          pointRadius: 10,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
        },{
          label: 'Aktuell volym: (Driftsvolym '+ facilityMaxvolume + 'L)',
          yAxisID: 'aktuellVolym',
          data: GettLast(actualVolumeData),
          fill: false,
          backgroundColor: 'rgb(161, 189, 240)',
          borderColor: 'rgba(42, 115, 250, 1)',
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          pointRadius: 10
        }]; 
      const PHtemp = [{
          label: 'PH Värde',
          yAxisID: 'PHvalu',
          data: GettLast(pHdata),
          fill: false,
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(128, 50, 67)',
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          pointRadius: 10
        },{
          label: 'Temperatur',
          yAxisID: 'temratur',
          data: GettLast(temperatureData),
          fill: false,
          backgroundColor: 'rgb(135, 245, 159)',
          borderColor: 'rgba(97, 177, 77, 1)',
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          pointRadius: 10
        }]; 
      const status = [{
          label: ['Vattenanalys - avläst värde i ml'],
          yAxisID: 'konsentrationId',
          data: GettLast(consentrasionData),
          fill: false,
          backgroundColor: 'rgba(156, 39, 176, 0.5)',
          borderColor: 'rgba(156, 39, 176, 1)',
          pointRadius: 10,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          range:['2020-04-04', '20122-08-06']
        },{
          yAxisID: 'Funktion',
          label: 'Funktion',
          data: GettLast(functionData),
          fill: false,
          backgroundColor: 'rgba(247, 200, 129, 0.5)',
          borderColor: 'rgb(187, 132, 87)',
          pointRadius: 10,
          cubicInterpolationMode: 'monotone',
          tension: 1,
        }];

        //select graphs
      if(type===4){
        setDataset({
          labels: GettLast(labelmedel),
          datasets: Settillsat(actualVolumeData, H2OData, productData, facilityMaxvolume)
        })
      }
      else if(type===0){
        setDataset({
          labels: labelsPh,
          datasets: setPHtemp( pHdata, temperatureData)
        })
      }
      else{
        setDataset({
          labels: GettLast(labelStatus),
          datasets: SettStatus(functionData,consentrasionData)
        })
      }
    }
  }, [data, type])

  if (isFetching || error || !data) {
    return (
      <S.SubPage>
        <S.LoadingIndicator>---</S.LoadingIndicator>
      </S.SubPage>
    );
  }

  //adjust button color and send diagram
  if (type===4){
    let documentElement =document.getElementById("medelId");
    if (documentElement !== null ) {
      document.querySelectorAll('.buttonClass').forEach(elem => {
        elem.setAttribute("style", "background: #2FC3E4");
      });
      documentElement.style.background = "#b9d763";
    }
    return <Line data={dataset} options={h20procuctOptions} type={'line'}/>;
  }
  else if(type===0){
    let documentElement =document.getElementById("phTempId");
    if (documentElement !== null ) {
      document.querySelectorAll('.buttonClass').forEach(elem => {
        elem.setAttribute("style", "background: #2FC3E4");
        });
      documentElement.style.background = "#b9d763";
    }
    return <Line data={dataset} options={phtemraturoptions} type={'line'}/>;
  }
  else{
    let documentElement =document.getElementById("statusId");
    if (documentElement !== null ) {
     document.querySelectorAll('.buttonClass').forEach(elem => {
        elem.setAttribute("style", "background: #2FC3E4");
      });
      documentElement.style.background = "#b9d763";
    }
    return <Line data={dataset} options={konsentOptions} type={'line'}/>;
  }
}

interface FacilityHistoryListProps {
  facility?: IFacility
}

const functionTypeString = (logtype: number) => {
  if(logtype === 3) return 'BRA'
  if(logtype === 2) return 'OKEJ'
  if(logtype === 1) return 'DÅLIG'
  if(logtype === 0) return 'saknar värde'
}

const loggingTypeString = (logtype: number) => {
  if(logtype === 0) return 'Analys'
  if(logtype === 1) return 'Badkorrigering'
  if(logtype === 2) return 'Nivåjustering'
  if(logtype === 4) return 'Redigerad anläggning'
}

//crate lower list 
const FacilityHistoryList = (props: HistroyFacilityPageProps) => {
  const { data, error, isFetching } = useHistory(props.facilityId || -1);

  if (isFetching || error || !data) {
    return (
      <S.SubPage>
        <S.LoadingIndicator>---</S.LoadingIndicator>
      </S.SubPage>
    );
  }

  const reversedData = data.slice().reverse();

  return (
    <S.PageMenu style={{ gap: '10px' }} justifyContent={"start"} fGrow={1}>
      {reversedData.map((log, index) => {
        const reversedIndex = reversedData.length - index - 1; // Calculate the reversed index
        return (
          <S.PageMenuItemHistoryLog key={reversedIndex} style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <small className="date">
                {log.facility_logdate + "(" + reversedIndex + ")" || <span>{log.facility_installation_date + "(" + reversedIndex + ")"}</span>}
              </small>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <small>Volym: {log.facility_volume || '0'}</small>
              <small>pH: {log.facility_ph || '?'}</small>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <small>Logtyp: {loggingTypeString(Number(log.facility_loggingtype)) || 'Okänd'}</small>
              <small>Funktion: {functionTypeString(Number(log.facility_function)) || 'Okänd'}</small>
            </div>
          </S.PageMenuItemHistoryLog>
        );
      })}
    </S.PageMenu>
  );
};


export const HistroyFacilityPage = (props: HistroyFacilityPageProps) => {
  const [type,setType] = useState(0)
  const { status, data, error, isFetching } = useFacilitiy(props.facilityId || -1)

  if (isFetching || error || !data) {
    return (
      <S.SubPage>
        <S.LoadingIndicator>---</S.LoadingIndicator>
      </S.SubPage>
    );
  }
  return (
   <S.SubPage>
     <Header/>
     <div>
      <h3>Historik</h3>
      <em>{data && data.name}</em>
    </div>
     <div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', marginTop: '5px'}}>
      <S.Button onClick={(e) => setType(0) } className="buttonClass" id="phTempId">pH / Temp °C</S.Button>
      <S.Button onClick={(e) => setType(4)} className="buttonClass" id="medelId">Tillsatta Medel</S.Button>
      <S.Button onClick={(e) => setType(5)} className="buttonClass" id="statusId">Status</S.Button>
     </div>
     <FacilityChart fid={data.id} type={type}/>
     <FacilityHistoryList facilityId={data.id}/>
   </S.SubPage>
  )
}
export default HistroyFacilityPage

/**
 *  gamla knapar
 * <Button onClick={(e) => setType(0)}>pH Värde</Button>
 * <Button onClick={(e) => setType(1)}>Aktuell volym</Button>
 * <Button onClick={(e) => setType(2)}>Temperatur</Button>
 * <Button onClick={(e) => setType(3)}>Funktion</Button>
 * <Button onClick={(e) => setType(4)}>TILLSATT H2O</Button>
 * <Button onClick={(e) => setType(5)}>koncentration produkt</Button>
 * <Button onClick={(e) => setType(5)}>TILLSATT Produkt</Button>
 * <Button onClick={(e) => setType(6)}>consentrasion produkt</Button>
 */