import axios from "axios"
import { useQuery } from "react-query"
import IHistoryLog from "../interfaces/IHistoryLog"



export const useHistory = (id: number) => {
  
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }

  return useQuery("facilitylog", async () => {
    const { data } = await axios.get(`https://envirostripp.se/wp-json/envapp/v1/logging/${id}`,{headers})
    if(!data || data === null) window.location.href='/'
    const json = JSON.parse(data) as IHistoryLog[];
    //console.log(json)
    return json
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  })
}