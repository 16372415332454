import { useMediaQuery } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import { useEffect } from "react"
import { useQueryClient } from "react-query"
import FacilityList from "../../components/FacilityList"
import Header from "../../components/Header"
import S from "../../components/styled"

export const HistoryPage = (props: RouteComponentProps) => {
  const queryClient = useQueryClient()
  useEffect(() => {
    queryClient.invalidateQueries(['facilitylog'])
  }, [])

  return (
   <S.SubPage>
     <Header/>
     <h3>Historik</h3>
     <FacilityList/>
   </S.SubPage>
  )
}
export default HistoryPage