import { Checkbox, Input, InputLabel } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Header from "../../components/Header";
import S from "../../components/styled";
import { useFacilitiy } from "../../hooks/useFacility";
import { fetchProduct } from "../../hooks/useProduct";
import useProductLabels from "../../hooks/useProductLabels";
import IProduct from "../../interfaces/IProduct";
import { IUpdateFacilityDto, updateFacility,} from "../../services/updateFacility";
import { formatDate } from "../../utils/formatDate";

interface LoggingFacilityPageProps extends RouteComponentProps {
  facilityId?: number;
}

export const LoggingFacilityPage = (props: LoggingFacilityPageProps) => {
  const queryClient = useQueryClient();
  const [desiredVolume, setDesiredVolume] = useState<number>();
  const [facilityVolume, setFacilityVolume] = useState<number>();
  const [calcResults, setCalcResults] = useState(0);
  const [addwater, setAddwater] = useState(0);
  const [addproduct, setAddproduct] = useState(0);
  const [relationship, setrelationship] = useState(" ");
  const [waterS, setwaterS] = useState(" ");
  const [productS, setproductS] = useState(" ");


  const [product, setProduct] = useState<IProduct>();
  //console.log(product);
  const productLabels = useProductLabels();

  const [confirmCheckWater, setConfirmCheckWater] = useState(false);
  const [confirmCheckProduct, setConfirmCheckProduct] = useState(false);

  const [selectedAdjustmentDate, setSelectedAdjustmentDate] = useState(
    formatDate(new Date())
  );

  const { status, data, error, isFetching } = useFacilitiy(
    props.facilityId || -1,
    
  );
  

  const calculateValues = () => {
    if (data && product) {
      const productValue = desiredVolume ?? 0 - data.facility_volume;
      const facilityVolumeValue = facilityVolume ?? 0;

      setCalcResults(Math.abs(productValue - facilityVolumeValue));
    }
    return 0;
  };

  const productconsenrasion= ()=>{
    if (product==null){
      console.log("isNull ");
      return 1;
    }else if(isNaN(product.ratio)){
      console.log("isNaN ");
      return 1;
    }
    if(product.name== "S3F"){
      return Number(product.ratio);
    }
    return Number(product.ratio);
  } 

  useEffect(() => {
    const findProduct = async () => {
      if (data) {
        const prod = await fetchProduct(data.facility_product);
        setProduct(prod);
        temp()
      }
    };

    findProduct();
  }, [data]);

  useEffect(() => {
    calculateValues();
  }, [desiredVolume]);
  const mutation = useMutation(
    (newFacilityLog: IUpdateFacilityDto) => updateFacility(newFacilityLog),
    {
      onSuccess: async (data, variables, context) => {
        //console.log(data);
        queryClient.invalidateQueries([]);
        window.history.back();
      },
    }
  );

  const [oldmax, setoldmax] =useState<number>();// kan finas en bätre men då data är fel de första gånerna dena ladas (om data har ändrats är det den gamla först och sen updateras den)
  if (data && (desiredVolume==null||desiredVolume==0 || oldmax!= data.facility_maxvolume)){
       setDesiredVolume(data.facility_maxvolume);
       setoldmax(data.facility_maxvolume);
  }

  if (isFetching || error || !data) {
    return (
      <S.SubPage>
        <S.LoadingIndicator>---</S.LoadingIndicator>
      </S.SubPage>
    );
  }

  function temp (newFacilitVolume?:number){
    if(newFacilitVolume){
      setFacilityVolume(Number(newFacilitVolume))
    }

    if(product?.name == "S3AI"){
      setAddwater(0)
      setAddproduct((desiredVolume || 0)- (newFacilitVolume || 0))
      setrelationship("Förhållande: 0:1")
    }else{
      setAddwater(((desiredVolume || 0) - (newFacilitVolume || 0)) * productconsenrasion() / ( productconsenrasion() + 1))
      setAddproduct(((desiredVolume || 0) - (newFacilitVolume || 0)) * 1 / ( productconsenrasion() + 1))
      setrelationship("Förhållande: 1:"+ ( productconsenrasion()))
    }
    
    setwaterS( addwater + " liter vatten")
    setproductS(addproduct +" liter")
  }


  return (
    <S.SubPage>
      <Header />
      <h3 style={{ margin: "8px" }}>Tillsättning - Nivåjustering</h3>
      <h3 style={{ margin: "8px" }}>{data && data.name}</h3>
      <S.PageMenu style={{ gap: "10px" }}>
        <InputLabel>Nivåjusteringsdatum: {selectedAdjustmentDate}</InputLabel>
        <Input
          value={selectedAdjustmentDate}
          onChange={(e) =>
            setSelectedAdjustmentDate(
              formatDate(new Date(e.currentTarget.value))
            )
          }
          type="date"
        />
        <em>Driftvolym (Önskad volym) i liter</em>
        {
        <em style={{ border: "none" }}>{desiredVolume}</em>
        /*<Input
          style={{ border: "none" }}
          value={desiredVolume}
          /*onChange={(e) => setDesiredVolume(Number(e.currentTarget.value))}*
          type="number"
        />*/}
        <em>Aktuell volym i liter</em>
        <Input
          style={{ border: "none" }}
          value={facilityVolume}
          onChange={(e) =>temp(Number(e.currentTarget.value))
          }
          type="number"
        />
        <h3 style={{ margin: 0 }}>{relationship}</h3>

        <span>
          <strong>Vatten</strong> {waterS} {/*to do fixs ratio  product.ratio  (( (product?.ratio|| 1) + 1))  */}
        </span>
        <span>
          <Checkbox checked={confirmCheckWater} onChange={(e) => setConfirmCheckWater(e.target.checked)} /> <small>Bekräfta tillsättning av vatten</small>
        </span>
        <span>
          <strong>{product && product.name}</strong> {productS} {/*to dofixs ratio*/}
        </span>
        <span>
          <Checkbox checked={confirmCheckProduct} onChange={(e) => setConfirmCheckProduct(e.target.checked)} /> <small>Bekräfta tillsättning av produkt</small>
        </span>

        <InputLabel>
          <strong>Total</strong> tillsättning: {calcResults} liter {"->"} ({" "}
          {desiredVolume}/{data.facility_maxvolume} liter )
        </InputLabel>
        {Number(desiredVolume) > data.facility_maxvolume && (
          <em style={{ textAlign: "center", color: "red" }}>
            Tillsättningen kommer resultera i att tanken spiller över.
          </em>
        )}
      </S.PageMenu>
      {confirmCheckWater && confirmCheckProduct ? (
        <S.Button
          onClick={async (e) => {
            if (data) {
              await mutation.mutateAsync({
                facility_id: data.id,
                facility_volume: desiredVolume,
                facility_loggingtype: 2,
                facility_updatedate: selectedAdjustmentDate,
                facility_addwater: ((desiredVolume || 0) - (facilityVolume || 0)) * productconsenrasion() / ( productconsenrasion() + 1), // to do find this  +++ dela på ratio
                facility_addproduct: ((desiredVolume || 0) - (facilityVolume || 0)) * 1 / ( productconsenrasion() + 1),
                //kanse fyla i ph värde om det går och konsentration
              });
            }
          }}
        >
          Genomför nivåjustering
        </S.Button>
      ) : (
        <>
          <em>
            Bekräfta både tillsättning av vatten och produkt för att gå vidare.
          </em>
          <S.Button style={{ opacity: 0.4 }}>Genomför nivåjustering</S.Button>
        </>
      )}
    </S.SubPage>
  );
};
export default LoggingFacilityPage;
