import { Link, RouteComponentProps } from "@reach/router"
import { useEffect, useState } from "react"
import Header from "../../components/Header"
import S from "../../components/styled"
import { Container, InputLabel,/* MenuItem,*/ NativeSelect,/* Select,*/ TextField, Typography } from '@material-ui/core'
import { useFacilitiy } from "../../hooks/useFacility"
import useStore from "../../stores"
//import useProductLabels from "../../hooks/useProductLabels"
import { fetchProduct, /*useProduct*/ } from "../../hooks/useProduct"
import IProduct from "../../interfaces/IProduct"
//import { config } from "node:process"
import constants from "../../constants"
import ProductOrderButton from '../../components/ProductOrderButton'

interface AnalyseFacilityPageProps extends RouteComponentProps{
  facilityId?: number
}
function vattenHaltcalc(vattenAnalys:number, MV:number, borVarde:number) {
  if (borVarde==87|| borVarde==83){//om det är X2G och M2G ska vattenHalt vara delad med 0.8
    return((vattenAnalys - MV)/0.8)
  }else{
    return(vattenAnalys - MV)
  }
}

export const AnalyseFacilityPage = (props: AnalyseFacilityPageProps) => {
  
  const { data, error, isFetching } = useFacilitiy(props.facilityId || -1)
  const [product, setProduct] = useState<IProduct>();
  //const calcResult = useStore(state => state.calcResult)
  const setCalcResult = useStore(state => state.setCalcResult)
  const setOldCalc = useStore(state => state.setOldCalc)
  //const calcResultType = useStore(state => state.calcResultType)
  const setCalcResultType = useStore(state => state.setCalcResultType)

  const [ vattenAnalys, setVattenAnalys ] = useState(0)
  const [ MV, setMV ] = useState(20)
  const [borVarde, setBorVarde] = useState(68)
  const [kemVariabel, setKemVariabel] = useState(44)
  const [ calcWater, setCalcWater ] = useState(0)
  const [ calcProduct, setCalcProduct ] = useState(0)
  const [ prodName, setname ] = useState('')

  //const [productFunction, setProductFunction] = useState<number>(0)
  //const productLabels = useProductLabels()

  const [actualVolume, setActualVolume] = useState<number>()

  const [temp, setTemp] = useState<number>()
  const [ph, setPH] = useState<number>()
  const [func, setFunc] = useState<number>()

  useEffect(() => {
    calculate()
  }, [vattenAnalys])

  useEffect(() => {
    setFunc(0);
    if(data) {
      //setActualVolume(data.facility_volume)//säter nuvarande ActualVolume som start värde
      //setTemp(data.facility_temperature)
      //setPH(data.facility_ph)
      //setFunc(data.facility_function)
      //console.log("test")
      //console.log(data.facility_function)
    }
    const fetchProductEffect = async () => {
      if(data && data?.facility_product){
        let res = await fetchProduct(data.facility_product)
        setProduct(res)
      }
    }
      fetchProductEffect()
      
  }, [data])

  useEffect(() => {
    if(product){
      setBorVarde(product.desireValue)
      setKemVariabel(product.chemValue)
      setname(product.name)
    }
    calculate()
  }, [product])

  useEffect(() => {
    setOldCalc({
      actualVolume: actualVolume || 0,
      function: func || 0,
      ph: ph || 0,
      temperature: temp || 0,
      vattenAnalys: vattenAnalys || 0,
    })
  }, [actualVolume, ph, func, temp, vattenAnalys])

  const calculate = () => {
    if(!data) return;
    const tankVolume = (actualVolume || 0)
    const vattenHalt = vattenHaltcalc(vattenAnalys, MV, borVarde)

    if (prodName== "S3F"){//calculate för S3F
      if(((vattenAnalys - 46)/50) > 0.08){
        setCalcWater(tankVolume*0.08)
        console.log(0.08)
      }else{
        setCalcWater((vattenAnalys - 46)/50*tankVolume)
      }
      setCalcProduct(0)
    }else{
      setCalcWater(((borVarde - vattenHalt) / (100 - borVarde)) * tankVolume)
    setCalcProduct(tankVolume * (vattenHalt - borVarde) / kemVariabel)
    }
    /*console.log("borVarde: "+ borVarde);//desireValue from produkt
    console.log("vattenHalt: "+vattenHalt); 
    console.log("vattenAnalys: "+vattenAnalys); //ska fram
    console.log("MV: "+MV); // 20
    console.log("tankVolume: "+tankVolume);// actualVolume || 0
    console.log("kemVariabel: "+kemVariabel);// chemValue from produkt
    console.log("calcWater: "+calcWater);// CalcProduct */
    console.log("calcProduct: "+calcProduct);// calcProduct 
    

    if(calcWater > 0) {
      setCalcResult(calcWater)
      setCalcResultType('Vatten')
    } else {
      setCalcResult(calcProduct)
      if(product){
        console.log("Product: "+product.name);// calcProduct 
        setCalcResultType(product.name)
      }else {
        setCalcResultType('...')
      }
    }
  }

  if(isFetching || error || !data) {
    return <S.SubPage>
      <S.LoadingIndicator>---</S.LoadingIndicator>
    </S.SubPage>
  }

  return (
   <S.SubPage>
     <Header/>
     <S.SubPageHeader>
      <h3>Analys</h3>
      <h3>{data.name}</h3>
     </S.SubPageHeader>
     <S.PageMenu>
       <InputLabel>Aktuell volym i liter</InputLabel>
       <TextField onKeyUp={(e) => calculate()} value={actualVolume} onChange={(e) => setActualVolume(Number(e.currentTarget.value))} type="number"/>
       <S.PageMenuDoubleColumn>
        <div>
          <InputLabel>Vattenanalys (avläst värde ml)</InputLabel>
          <TextField onKeyUp={(e) => calculate()} onChange={(e) => setVattenAnalys(Number(e.currentTarget.value))} type="number"/>
        </div>
        <div>
          <InputLabel>pH-värde</InputLabel>
          <TextField onChange={(e) => setPH(Number(e.currentTarget.value))} 
          value={ph} 
          type="number" 
          inputProps={{
            step: "0.1"
          }} />
        </div>
        </S.PageMenuDoubleColumn>
        <S.PageMenuDoubleColumn>
        <div>
          <InputLabel id="productFunctionLabel">Funktion</InputLabel>
          <NativeSelect onChange={(e) => setFunc(Number(e.currentTarget.value))} defaultValue={func} style={{width: '100%'}} >
            <option value={3}>BRA</option>
            <option value={2}>OKEJ</option>
            <option value={1}>DÅLIG</option>
            <option value={0}>-</option>
          </NativeSelect>
        </div>
        <div>
          <InputLabel>Temperatur (c)</InputLabel>
          <TextField onChange={(e) => setTemp(Number(e.currentTarget.value))} value={temp} type="text"/>
        </div>
        </S.PageMenuDoubleColumn>
        <Container style={{border: '1px dotted black', padding: '4px'}}>
          <Typography variant={"subtitle1"}><strong>Nuvarande status</strong></Typography>
          <Typography variant={"subtitle2"}><strong>Produkt:</strong> {product && product.name}</Typography>
          {product && <ProductOrderButton product={product}/>}
          <Typography variant={"subtitle2"}><strong>Anläggningstyp:</strong> {constants.FACILITY_TYPES[data.facility_type]}</Typography>
          {/* actualVolume && actualVolume > 0 && <Typography variant={"subtitle2"}><strong>Aktuell volym:</strong> {actualVolume}L</Typography>*/}
          <Typography variant={"subtitle2"}><strong>Aktuell volym:</strong> {data.facility_volume}L</Typography>
          <Typography variant={"subtitle2"}><strong>Driftsvolym:</strong> {data.facility_maxvolume || 0} L</Typography>
          <Typography variant={"subtitle2"}><strong>pH:</strong> {data.facility_ph || 0}</Typography>
          <Typography variant={"subtitle2"}><strong>Temperatur:</strong> {data.facility_temperature || 0}</Typography>
          <Typography variant={"subtitle2"}><strong>Funktion:</strong> {constants.FUNCTION_LABELS[data.facility_function]}</Typography>
      </Container>
     </S.PageMenu>
     <S.Button onClick={() => calculate()}><Link to="calculate">Beräkna</Link></S.Button>
     <S.Button onClick={() => calculate()}><Link to={`/history/${data.id}`}>Historik</Link></S.Button>
   </S.SubPage>
  )
}

// <S.Button><Link to="calculate">Beräkna</Link></S.Button>
// { actualVolume && actualVolume > 0 && <Typography variant={"subtitle2"}><strong>Aktuell volym:</strong> {actualVolume}L</Typography>}
export default AnalyseFacilityPage