import axios from "axios"

export interface IUpdateFacilityDto {
  name?: string, 
  facility_id?: number,
  facility_volume?: number,
  facility_function?: number,
  facility_ph?: number,
  facility_temperature?: number,
  facility_updatedate?: string,
  facility_loggingtype?: number,
  facility_product?: number,
  facility_addproduct?: number,
  facility_addwater?: number,
  facility_maxvolume?: number,
  facility_meassured?: number,
}

export const updateFacility = async (props: IUpdateFacilityDto) => {
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }
  const res =  await axios.post(`https://envirostripp.se/wp-json/envapp/v1/updatefacilities/${props.facility_id}`, props,{ 
    headers: headers
  })

  return res
}
