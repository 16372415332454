import { Link, RouteComponentProps } from "@reach/router"
import FacilityList from "../../components/FacilityList"
import Header from "../../components/Header"
import S from "../../components/styled"
import {  useFacilities } from "../../hooks/useFacilities"


export const FacilitiesPage = (props: RouteComponentProps) => {

  return (
   <S.SubPage>
     <Header/>
     <h2>Anläggningar</h2>
     <FacilityList/>
     <S.Button><Link to="create">Lägg till en anläggning</Link></S.Button>
   </S.SubPage>
  )
}
export default FacilitiesPage