import axios from "axios"
import { useQuery } from "react-query"
import IProduct from "../interfaces/IProduct"

export const useProducts = () => {
  
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }

  return useQuery("products", async () => {
    const { data } = await axios.get(`https://envirostripp.se/wp-json/envapp/v1/products`,{headers})
    console.log(data)
    if(!data || data === null) return [{id: 0, name: ''}] as IProduct[]
    return JSON.parse(data) as IProduct[]
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  })
}