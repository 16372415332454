import { /*Link,*/ RouteComponentProps } from "@reach/router"
import axios from "axios"
import { useEffect, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import Header from "../../components/Header"
import S from "../../components/styled"
import { useFacilitiy } from "../../hooks/useFacility"
import { fetchProduct, /*useProduct*/ } from "../../hooks/useProduct"
//import { useProducts } from "../../hooks/useProducts"
import IProduct from "../../interfaces/IProduct"
import { IUpdateFacilityDto, updateFacility } from "../../services/updateFacility"
import useStore from "../../stores"

interface FacilityEditPageProps extends RouteComponentProps{
  facilityId?: number
}

export const FacilityEditPage = (props: FacilityEditPageProps) => {
  const { /*status,*/ data, error, isFetching } = useFacilitiy(props.facilityId || -1)
  const [product, setProduct] = useState<IProduct>();
  const queryClient = useQueryClient()
  const [remCountDown, setRemCountDown] = useState(6);

  const [actualVolume, setActualVolume] = useState<number>()
  const [facilityMaxvolume, setFacilityMaxvolume] = useState<number>()
  const [facilityName, setFacilityName] = useState<string>()

  const facilities = useStore(state => state.facilities)

  const mutation = useMutation(
    (facility: IUpdateFacilityDto) => updateFacility(facility),
    {
      onSuccess: async (/*data, variables, context*/) => {
        queryClient.invalidateQueries();
        window.history.back();
      },
    }
  );

  useEffect(() => {
    const deleteFacility = async () => {
      const headers = {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
      }
      const res =  await axios.post(`https://envirostripp.se/wp-json/envapp/v1/deletefacilities/${data?.id}`,{ 
        headers: headers
      })
      queryClient.invalidateQueries()
      window.location.href='/facilities'
    }

    if(remCountDown < 1) {
      if(window.confirm('Är du fortfarande säker att du vill ta bort anläggningen?')){
        deleteFacility()
      }
    }
  }, [remCountDown])

  useEffect(() => {
    if(data) {
      setActualVolume(data.facility_volume)
      setFacilityMaxvolume(data?.facility_maxvolume)
      setFacilityName(data.name)
      
    }

    const fetchProductEffect = async () => {
      if(data && data?.facility_product){
        let res = await fetchProduct(data.facility_product)
        setProduct(res)
      }
    }
    
    fetchProductEffect()
  }, [data])

  if(isFetching || error || !data) {
    return <S.SubPage>
      <S.LoadingIndicator>---</S.LoadingIndicator>
    </S.SubPage>
  }
  
  return (
   <S.SubPage>
     <Header/>
     <S.SubPageTitleText>
      <h3 className="title">Ändra Anläggning</h3>
      <h3 className="data">{data && data.name}</h3>
     </S.SubPageTitleText>
     <S.PageMenu justifyContent={"start"}>
     <S.InputCheck>
          <label>
            namn på anläggningen
          </label>
          <div>
            <input type="text" value={facilityName|| 0} onChange={(e) => setFacilityName(String(e.target.value))}/>
          </div>
        </S.InputCheck>
      <S.InputUnCheck>
          <label>
            Typ av anläggning
          </label>
          <div>
            <input type="text" value={facilities[data?.facility_type || 0]}/>
          </div>
        </S.InputUnCheck>
        <S.InputUnCheck>
          <label>
            Produkt
          </label>
          <div>
            <input type="text" value={product && product.name}/>
          </div>
        </S.InputUnCheck>
        <S.InputUnCheck>
          <label>
            Aktuell volym (liter)
          </label>
          <div>
            <input type="text" value={data.facility_volume || 0}/>
          </div>
        </S.InputUnCheck>
        <S.InputCheck>
          <label>
            Driftsvolym (liter)
          </label>
          <div>
            <input type="text" value={facilityMaxvolume|| 0} onChange={(e) => setFacilityMaxvolume(Number(e.target.value))}/>
          </div>
        </S.InputCheck>
        <S.InputUnCheck>
          <label>
            Installationsdatum
          </label>
          <div>
            <input type="text" value={data?.facility_installation_date}/>
          </div>
        </S.InputUnCheck>
        <S.Button onClick={async (e) => {
            await mutation.mutateAsync({
              facility_id: data.id,
              name:facilityName,
              facility_volume: actualVolume,
              facility_loggingtype: 4,
              facility_maxvolume : facilityMaxvolume,
            });
          }}>Uppdatera anläggning</S.Button>
     </S.PageMenu>
     <div>
      <S.Button onClick={(e) => setRemCountDown(0)} style={{width: '90%', backgroundColor: 'red'}}>Ta bort anläggning</S.Button>
     </div>
   </S.SubPage>
  )
}
export default FacilityEditPage