import axios from "axios"
import { useQuery } from "react-query"
import IFacility from "../interfaces/IFacility"

export const useFacilitiy = (id: number) => {
  
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }

  return useQuery("facility", async () => {
    const { data } = await axios.get(`https://envirostripp.se/wp-json/envapp/v1/facilities/${id}`,{headers})
    if(!data || data === null) window.location.href='/'
    
    return JSON.parse(data) as IFacility
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  })
}