import styled, { keyframes } from "styled-components";

const BaseApp = styled.div`
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
const BaseApp2 = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & > * {
    width: 98%;
  }
  & > Button {
    width: 40%;
    margin: 45%;
  }
`

const SubPage = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  
  & > Button {
    margin: 5%;
  }
`

const SubPageHeader = styled.div`

`

const DebugBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  background: black;
  color: white;
  padding: 2px;
  font-size: 10px;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
    font-size: 100%;
  }

  to  {
    transform: rotate(360deg);
    font-size:200%;
  }
`;
const LoadingIndicator = styled.div`
  flex-grow: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:30px;
  text-align:center;
  animation: ${rotate} 2s linear infinite;
`;

const BackButton = styled.div`
  background-image: url('https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/tillbaka.png');
  background-size: contain;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  width:40px;
  height:40px;
`

const HomeButton = styled.div`
  background-image: url('https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/hem.png');
  background-size: contain;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  width:40px;
  height:40px;
`

const Logo = styled.div`
  background-image: url('https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: 1s all;
  height: 160px;
  width: 80%;
`;

const LogoSmall = styled.div`
  background-image: url('https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: 1s all;
  height: 40px;
  width: 80px;
  flex-grow: 1;
`;


const Button = styled.button`
  display: inline-flex;
  background: #2FC3E4;
  padding: 8px;
  color: white;
  border: none;
  border-radius: 10px;
  justify-content: center;
  font-size:22px;
  outline: none;
  cursor:pointer;
  font-weight:bold;

  a {
    width: 100%;
    color: white;
    text-decoration: none;
  }
`
const Button2 = styled.button`
  display: inline-flex;
  background: #2FC3E4;
  padding: 8px;
  color: white;
  border: none;
  border-radius: 10px;
  justify-content: center;
  font-size:22px;
  outline: none;
  cursor:pointer;
  font-weight:bold;
  width: 50%;
  margin-right: 25%;
  margin-left: 25%;

  a {
    width: 100%;
    color: white;
    text-decoration: none;
  }
`

const Navigation = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 3%;
  justify-content: center;
  flex-direction: column;
  transition: 1s all;
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 40px 0px;
  background-color:#B9D764;
  padding:20px;
  border-radius: 10px;
`;

const Input = styled.input`
  padding:10px;
  border: none;
  border-radius: 10px;
  //color: '#747474';
  font-size:18px;
`

const Contact = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  color: #CECECE;

  div {
    text-align: center;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid black;
  max-height:60px;
  height:60px;

  img {
    height : 100%;
    cursor: pointer;
  }
` 

const InputCheck = styled.div`
  display: flex;
  font-size: 23px;
  padding: 10px;
  justify-content: space-between;
  box-shadow: 0px 5px 5px #dddddd;
  label {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    color: black;
    text-decoration: none;
    border: none;
  }

  div {
    width: 40%;
  }

  div > input {
    text-align: right;
    box-shadow: none;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
  }
`
const InputUnCheck = styled.div`
  display: flex;
  font-size: 23px;
  padding: 10px;
  justify-content: space-between;
  box-shadow: 0px 5px 5px #dddddd;
  label {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    color: gray;
    text-decoration: none;
    border: none;
  }

  div {
    width: 40%;
  }

  div > input {
    color: gray;
    text-align: right;
    box-shadow: none;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
  }
`


interface PageMenuProps {
  justifyContent?: string,
  direction?: string
  fGrow?: number
}

const PageMenu = styled.nav`
  flex-grow: 1;
  display: flex;
  flex-direction: ${(props: PageMenuProps) => props.direction || 'column'};
  list-style-type: none;
  padding: 20px;
  background: #faf9f9;
  gap: 20px;
  margin: 0;
  text-align: left;
  color: black;

  overflow-y: auto;

  justify-content: ${(props: PageMenuProps) => props.justifyContent || 'start'};
  
  img {
    width:70px;
    height: 70px;
  }

  a, li, & > div {
    background: white;
    
  }

  & > a, input, select, li {
    font-size: 18px;
    padding: 10px;
    margin: 1% 0px;
    box-shadow: 0px 5px 5px #dddddd;
    display:inline-block;
    color: black;
    text-decoration: none;
    border: none;
  }

  .details {
    background: rgb(240,240,240);
    font-size: 12px;
    padding: 2px;
    float: right;
    display: flex;
    
  }
`

const FormInput = styled.input `
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 10px;
  gap: 15px;
  margin: 0;
  text-align: left;
  font-size: 16px;
  padding: 10px;
  box-shadow: 0px 5px 5px #dddddd;
  border: none;
  display:inline-block;
  color: black;
  text-decoration: none;
`

const PageMenuItem = styled.li`
  padding: 10px;
  box-shadow: 0px 5px 5px #dddddd;
  display:inline-block;
`

const PageMenuItemHistoryLog = styled.li`
  padding: 10px;
  box-shadow: 0px 5px 5px #dddddd;
  font-size: 14px;
  display:inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  small.date {
    color: grey;
  }
`

const SubPageTitleText = styled.div`
  padding: 16px 0px;
  h3 {
    font-size: 16px;
    padding: 4px;
    margin: 0px 4px;
  }
`
const ContactLink = styled.a`
  padding: 16px 0px;
  color: #2fc3e4;
  
`

const PageMenuDoubleColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > * {
    width: 48%;
  }
`

const S = {
  BaseApp,
  BaseApp2,
  SubPage,
  PageMenuDoubleColumn,
  SubPageHeader,
  Button,
  Button2,
  HomeButton,
  BackButton,
  Navigation,
  Contact,
  Logo,
  LogoSmall,
  LoginForm,
  Header,
  Input,
  PageMenu,
  PageMenuItem,
  PageMenuItemHistoryLog,
  LoadingIndicator,
  FormInput,
  InputCheck,
  InputUnCheck,
  SubPageTitleText,
  DebugBox,
  ContactLink
}

export default S;