import axios from "axios"
import { useQuery } from "react-query"

export interface Organization {
  name: string,
  org_product_manager: string
}

export const useOrganization = () => {

  // 

  return JSON.parse(localStorage.getItem('user') || '').profile.user_organization as Organization
  
  /*const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }

  return useQuery("organization", async () => {
    const { data } = await axios.get(`https://envirostripp.se/wp-json/envapp/v1/organization`,{headers})
    console.log(data)
    if(!data || data === null) return {name: 'Saknar orginisation'} as Organization
    return JSON.parse(data) as Organization
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  }) */
}

