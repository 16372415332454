import axios from "axios"
import { useQuery } from "react-query"

export interface IProfile {
  org_product_manager: "ruben@ingridgopa.se",
  user_display_name: string,
  user_email: string,
  user_first_name: string,
  user_last_name: string,
  user_nicename: string,
  user_organization: string,
  user_role: string,
}

export const useProfile = () => {

  // 

  return JSON.parse(localStorage.getItem('user') || '').profile as IProfile;
  
  /*const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }

  return useQuery("organization", async () => {
    const { data } = await axios.get(`https://envirostripp.se/wp-json/envapp/v1/organization`,{headers})
    console.log(data)
    if(!data || data === null) return {name: 'Saknar orginisation'} as Organization
    return JSON.parse(data) as Organization
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
  }) */
}

