import { Router } from '@reach/router';
import React, { useEffect } from 'react';
import './App.css';
import AnalyseFacilityCalculatePage from './pages/Analyse/AnalyseFacilityCalculatePage';
import AnalyseFacilityPage from './pages/Analyse/AnalyseFacilityPage';
import AnalysisPage from './pages/Analyse/AnalysisPage';
import ContactPage from './pages/Contact/ContactPage';
import CorrectionPage from './pages/Correction/CorrectionPage';
import FacilitiesPage from './pages/Facilities/FacilitiesPage';
import FacilityCreatePage from './pages/Facilities/FacilityCreatePage';
import FacilityEditPage from './pages/Facilities/FacilityEditPage';
import FacilityPage from './pages/Facilities/FacilityPage';
import HistroyFacilityPage from './pages/History/HistoryFacilityPage';
import HistoryPage from './pages/History/HistoryPage';
import LoggingFacilityPage from './pages/Logging/LoggingFacilityPage';
import LoggingPage from './pages/Logging/LoggingPage';
import OrderPage from './pages/Order/OrderPage';
import StartPage from './pages/StartPage';
import useStore from './stores';

function App() {
  const signedIn = useStore(state => state.signedIn)
  const setSignedIn = useStore(state => state.setSignedIn)

  useEffect(() => {
    if(localStorage.getItem('user')) {
      setSignedIn()
    }
  },)

  return (
    <Router className="App">
        <StartPage default path="/"/>
        { signedIn && (
          <>
          <AnalysisPage path="analysis"/>
          <AnalyseFacilityPage path="analysis/:facilityId"/>
          <AnalyseFacilityCalculatePage path="analysis/:facilityId/calculate"/>

          <CorrectionPage path="correction/:facilityId"/>

          <FacilitiesPage path="facilities"/>
          <FacilityPage path="facilities/:facilityId"/>
          <FacilityEditPage path="facilities/:facilityId/edit"/>
          <FacilityCreatePage path="facilities/create"/>

          <LoggingPage path="logger"/>
          <LoggingFacilityPage path="logger/:facilityId"/>
          
          <OrderPage path="order"/>

          <ContactPage path="contact"/>

          <HistoryPage path="history"/>
          <HistroyFacilityPage path="history/:facilityId"/>
          </>
        )}
      </Router>
  );
}

/*<S.Contact>
        <div>Kontakt</div>
        <div>Support</div>
        <div>tel1: 12345</div>
        <div>support@envirostripp.se</div>
        <div>Ekonomi</div>
        <div>tel2: 12345</div>
      </S.Contact> */

export default App;
