import { Container, Input, InputLabel, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Header from "../../components/Header";
import S from "../../components/styled";
import { useFacilitiy } from "../../hooks/useFacility";
import useStore from "../../stores";
import constants from "../../constants";
import IProduct from "../../interfaces/IProduct";
import { fetchProduct } from "../../hooks/useProduct";
import {
  IUpdateFacilityDto,
  updateFacility,
} from "../../services/updateFacility";
import { formatDate } from "../../utils/formatDate";
import ProductOrderButton from "../../components/ProductOrderButton";

interface AnalyseFacilityCalculatePageProps extends RouteComponentProps {
  facilityId?: number;
}

export const AnalyseFacilityCalculatePage = (
  props: AnalyseFacilityCalculatePageProps
) => {
  const queryClient = useQueryClient();
  const { data, error, isFetching } = useFacilitiy(props.facilityId || -1);
  //console.log( "här props.facilityId")
  //console.log( useFacilitiy(props.facilityId || -1))

  const calcResult = useStore((state) => state.calcResult);
  const calcResultType = useStore((state) => state.calcResultType);
  const [confirmCheck, setConfirmCheck] = useState(false);

  const [selectedAnalyticDate, setSelectedAnalyticDate] = useState(
    formatDate(new Date())
  );

  const mutation = useMutation(
    (facility: IUpdateFacilityDto) => updateFacility(facility),
    {
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries();
        window.history.back();
      },
    }
  );

  const [product, setProduct] = useState<IProduct>();
  const oldCalc = useStore((state) => state.oldCalc);
  const skip = Number(0);
  //console.log("här oldCalc");
  //console.log(oldCalc);

  useEffect(() => {
    const findProduct = async () => {
      if (data) {
        const prod = await fetchProduct(data.facility_product);
        setProduct(prod);
      }
    };
    findProduct();
  }, []);

  if (isFetching || error || !data) {
    return (
      <S.SubPage>
        <S.LoadingIndicator>---</S.LoadingIndicator>
      </S.SubPage>
    );
  }

  return (
    <S.SubPage>
      <Header />
      <S.SubPageHeader>
        <h3>Resultat</h3>
        <h3>{data && data.name}</h3>
      </S.SubPageHeader>
      <S.PageMenu>
        <S.InputCheck>
          <label>
            <span>
              Tillsätt {Math.round(calcResult)} Liter {calcResultType}
            </span>
            <span>Bekräfta tillsättning </span>
          </label>
          
          <span style={{ marginLeft: "10px" }}>
            <input
              style={{transform: "scale(2)"}}
              type="checkbox"
              checked={confirmCheck}
              onChange={(e) => setConfirmCheck(e.currentTarget.checked)}
            />
          </span>
        </S.InputCheck>
        {
            product && <ProductOrderButton product={product}/>
        }
        <InputLabel>Analysdatum: {selectedAnalyticDate}</InputLabel>
        <Input
          value={selectedAnalyticDate}
          onChange={(e) =>
            setSelectedAnalyticDate(formatDate(new Date(e.currentTarget.value)))
          }
          type="date"
        />
        {(Number(oldCalc.actualVolume) + Number(Math.round(calcResult)))- Number(data.facility_maxvolume) >
          0 && (
          <h2 style={{ textAlign: "center", color: "red" }}>
            Varning! Risk för överfyllnad med { (Number(oldCalc.actualVolume) + Number(Math.round(calcResult)))- Number(data.facility_maxvolume)}L
          </h2>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Container
            style={{
              border: "1px dotted black",
              margin: "8px",
              padding: "8px",
            }}
          >
            <Typography variant={"subtitle1"}>Före tillsättning</Typography>
            <Typography variant={"subtitle2"}>
              <strong>Driftsvolym:</strong> {data.facility_maxvolume || 0}L
            </Typography>
            <Typography variant={"subtitle2"}>
              <strong>Aktuell volym:</strong> {oldCalc.actualVolume}L
            </Typography>
            <Typography variant={"subtitle2"}>
              <strong>Funktion:</strong>{" "}
              {constants.FUNCTION_LABELS[data.facility_function || 0]}
            </Typography>
            <Typography variant={"subtitle2"}>
              <strong>PH:</strong> {data.facility_ph || 0.0}
            </Typography>
            <Typography variant={"subtitle2"}>
              <strong>Temperatur:</strong> {data.facility_temperature || 0}
            </Typography>
          </Container>
          <div></div>
          <Container
            style={{
              border: "1px dotted black",
              margin: "8px",
              padding: "8px",
            }}
          >
            <Typography variant={"subtitle1"}>Efter tillsättning</Typography>
            <Typography variant={"subtitle2"}>
              <strong>Driftsvolym:</strong> {data.facility_maxvolume}L
            </Typography>
            <Typography variant={"subtitle2"}>
              <strong>Ny volym:</strong>{" "}
              {Number(oldCalc.actualVolume) + Number(Math.round(calcResult))}L
            </Typography>
            {oldCalc && (
              <>
                <Typography variant={"subtitle2"}>
                  <strong>Funktion:</strong>{" "}
                  {constants.FUNCTION_LABELS[oldCalc.function]}
                </Typography>
                <Typography variant={"subtitle2"}>
                  <strong>PH:</strong> {oldCalc.ph}
                </Typography>
                <Typography variant={"subtitle2"}>
                  <strong>Temperatur:</strong> {oldCalc.temperature}
                </Typography>
              </>
            )}
          </Container>
        </div>
      </S.PageMenu>
      {confirmCheck ? (
        <S.Button
          style={{
           marginTop:"1%",
           marginBottom:"1%"
          }}
          onClick={async (e) => {
            await mutation.mutateAsync({
              facility_id: data.id,
              facility_volume: Number(oldCalc.actualVolume) + Number(Math.round(calcResult)),
              facility_function: oldCalc.function,
              facility_ph: oldCalc.ph,
              facility_temperature: oldCalc.temperature,
              facility_updatedate: selectedAnalyticDate,
              facility_loggingtype: 0,
              facility_product: data.facility_product,
              facility_addproduct: calcResultType === "Vatten" ? 0 : calcResult,// flipade?
              facility_addwater: calcResultType === "Vatten" ? calcResult : 0,
              facility_meassured: oldCalc.vattenAnalys,
            });
          }}
        >
          Bekräfta
        </S.Button>
      ) : (
        <>
          <em>Fyll i checkrutan för att kunna bekräfta.</em>
          <S.Button style={{ opacity: "0.4",marginTop:"1%",marginBottom:"1%"}}>Bekräfta</S.Button>
        </>
      )}
      <S.Button
          style={{
             marginTop:"1%",
             marginBottom:"1%"
            }}
          onClick={async (e) => {
            await mutation.mutateAsync({
              facility_id: data.id,
              facility_volume: Number(oldCalc.actualVolume) + Number(0),
              facility_function: oldCalc.function,
              facility_ph: oldCalc.ph,
              facility_temperature: oldCalc.temperature,
              facility_updatedate: selectedAnalyticDate,
              facility_loggingtype: 0,
              facility_product: data.facility_product,
              facility_addproduct: Number(0),
              facility_addwater: Number(0),
              facility_meassured: oldCalc.vattenAnalys,
            });
          }}
        >
          Spara, Jag tillsätter senare
        </S.Button>
    </S.SubPage>
  );
};
export default AnalyseFacilityCalculatePage;
