import { useOrganization } from "../hooks/useOrganization";
import { useProfile } from "../hooks/useProfile";
import IProduct from "../interfaces/IProduct";

interface IProductOrderButtonProps {
  product: IProduct;
}

const ProductOrderButton = ({ product }: IProductOrderButtonProps) => {
  const organization = useOrganization();
  const profile = useProfile();
  if (profile.user_role === "Användare") {
    return (
      <a
        href={`mailto:${profile.org_product_manager}?subject=Best%C3%A4llning%20av%20${product.name}&body=Hej%2C%20jag%20beh%C3%B6ver%20best%C3%A4lla%20av%20${product.name}`}
      >
        Beställ mer av {product.name}
      </a>
    );
  } else {
    return (
      <h6 style={{ padding: 0, margin: "0" }}>
        <a
          href={`https://envirostripp.se${product?.product_link}`}
        >
          Klicka här för att beställa mer av {product.name}
        </a>
      </h6>
    );
  }
};

export default ProductOrderButton;
