import { RouteComponentProps } from "@reach/router"
import axios from "axios"
import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import Header from "../../components/Header"
import S from "../../components/styled"
import { useProducts } from "../../hooks/useProducts"

interface CreateFacilityDto {
  name?: string,
  facility_type?: number,
  facility_product?: number,
  facility_volume?: number,
  facility_maxvolume?: number,
  facility_function?: number,
  facility_ph?: number,
  facility_temperature?: number,
  facility_installation_date?: string
}

const createFacility = async (props: CreateFacilityDto) => {
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }
  const res =  await axios.post('https://envirostripp.se/wp-json/envapp/v1/facilities', props,{ 
    headers: headers
   })
  return res
}

export const FacilityCreatePage = (props: RouteComponentProps) => {

  const queryClient = useQueryClient()
  
  
  const [facilityName, setFacilityName] = useState<string>('')
  const [facilityProduct, setFacilityProduct] = useState<number>(-1)
  const [facilityType, setFacilityType] = useState<number>(-1)
  const [facilityMaxVolume, setFacilityMaxVolume] = useState<number>()
  
  const [facilityDate, setFacilityDate] = useState<string>('')
  
  const { data, error, isFetching } = useProducts()
  const mutation = useMutation((newFacility: CreateFacilityDto) => createFacility(newFacility), {
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries()
      window.history.back()
    },
  })

  if (isFetching || error || !data) {
    return (
      <S.SubPage>
        <S.LoadingIndicator>---</S.LoadingIndicator>
      </S.SubPage>
    );
  }

  return (
   <S.SubPage>
     <Header/>
     <S.SubPageTitleText>
      <h3 className="title">Skapa en anläggning</h3>

     </S.SubPageTitleText>
     <S.PageMenu>
      <input value={facilityName} onChange={(e) => setFacilityName(e.currentTarget.value)} type="text" placeholder="Anläggningsnamn"/>
      <select defaultValue="-1" onChange={(e) => setFacilityType(Number(e.currentTarget.value))}>
        <option value="-1" disabled>Typ av anläggning</option>
        <option value="0">Spoltvätt</option>
        <option value="1">Dopptvätt</option>
      </select>
      <select defaultValue="-1" onChange={(e) => setFacilityProduct(Number(e.currentTarget.value))}>
        <option value="-1" disabled>Produkt</option>
        {data && data.map((product) => <option value={product.id}>{product.name}</option>)}
      </select>
      <input value={facilityMaxVolume} onChange={(e) => setFacilityMaxVolume(Number(e.currentTarget.value))} type="number" placeholder="Driftsvolym (liter)" min={0}/>
      <S.PageMenuDoubleColumn>
      <input value={facilityDate} onChange={(e) => setFacilityDate(e.currentTarget.value)} type="date" placeholder="Installationsdatum"/>
      </S.PageMenuDoubleColumn>
     </S.PageMenu>
     <S.Button onClick={async (e) => {
       await mutation.mutateAsync({
         name: facilityName,
         facility_product: facilityProduct,
         facility_type: facilityType,
         facility_maxvolume: facilityMaxVolume,
         facility_installation_date: facilityDate
       })
     }}>Skapa anläggning</S.Button>
   </S.SubPage>
  )
}
export default FacilityCreatePage