import React from "react"
import BackButton from "./BackButton"
import HomeButton from "./HomeButton"
import Logo from "./Logo"
import S from "./styled"

const Header = () => {
  return <S.Header>
    <BackButton/>
    <Logo/>
    <HomeButton/>
  </S.Header>
}

export default Header