import { Container, Typography } from "@material-ui/core"
import { Link } from "@reach/router"
import { useFacilities } from "../hooks/useFacilities"
import IFacility from "../interfaces/IFacility"
import S from "./styled"

export const FacilityList = () => {
  const { data, error, isFetching } = useFacilities()
  
  if(isFetching || error) {
    return <S.LoadingIndicator>---</S.LoadingIndicator>
  }
  
  if(data && data.length === 0){
    return <S.PageMenu><h3 style={{textAlign: 'center'}}>Det finns inga anläggningar</h3></S.PageMenu>
  }

  return (
    <>
    <S.PageMenu>
      <strong>Anläggningar: </strong>
      {data && data.sort((a, b) => {
        if( a.name > b.name ) return 1;
        if( a.name < b.name ) return -1;
        return 0 
      }).map(({id, name, facility_installation_date, facility_product, facility_type, facility_volume, facility_maxvolume}:IFacility) => <li key={id}>
      <Container>
        <div style={{padding: '4px'}}>
          <Typography variant={"subtitle1"}>{name}</Typography>
          <Typography variant={"subtitle2"} style={{background: '#f7f7f7'}}>Driftsvolym (önskad volym):{facility_maxvolume}l</Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '6px'}}>
          <Link style={{textDecoration: 'none'}} to={`/analysis/${id}`}><S.Button>Analys</S.Button></Link>
          <Link style={{textDecoration: 'none'}} to={`/correction/${id}`}><S.Button>Badkorrigering</S.Button></Link>
        </div>
      </Container>
    </li>)}
    </S.PageMenu>
    </>
  )
}

export default FacilityList