import { Link, RouteComponentProps } from "@reach/router"
import { useEffect, useState } from "react"
import Header from "../../components/Header"
import S from "../../components/styled"
import { useFacilitiy } from "../../hooks/useFacility"
import { fetchProduct, useProduct } from "../../hooks/useProduct"
import { useProducts } from "../../hooks/useProducts"
import IProduct from "../../interfaces/IProduct"
import useStore from "../../stores"

interface FacilityPageProps extends RouteComponentProps{
  facilityId?: number
}

export const FacilityPage = (props: FacilityPageProps) => {
  const { status, data, error, isFetching } = useFacilitiy(props.facilityId || -1)
  const [product, setProduct] = useState<IProduct>();

  const facilities = useStore(state => state.facilities)

  useEffect(() => {
    const fetchProductEffect = async () => {
      if(data && data?.facility_product){
        let res = await fetchProduct(data.facility_product)
        setProduct(res)
      }
    }
    
    fetchProductEffect()
  }, [data])

  if(isFetching || error || !data) {
    return <S.SubPage>
      <S.LoadingIndicator>---</S.LoadingIndicator>
    </S.SubPage>
  }
  
  return (
   <S.SubPage>
     <Header/>
     <S.SubPageTitleText>
      <h3 className="title">Anläggning</h3>
      <h3 className="data">{data && data.name}</h3>
     </S.SubPageTitleText>
     <S.PageMenu justifyContent={"center"} direction={"row"} style={{flexGrow: 0}}>
       <Link to={`/analysis/${data?.id}`} style={{textAlign: 'center'}}>
        <img src="https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/rakna.png" alt="Analys"/>
        <h3>Analys</h3>
       </Link>
       <Link to={`/history/${data?.id}`} style={{textAlign: 'center'}}>
        <img src="https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/historik.png" alt="Historik"/>
        <h3>Historik</h3>
       </Link>
       <Link to={`/facilities/${data?.id}/edit`} style={{textAlign: 'center'}}>
        <img src="https://envirostripp.se/wp-content/plugins/envirostripp-app/app/assets/kugghjul.png" alt="Redigera"/>
        <h3>Redigera</h3>
       </Link>
     </S.PageMenu>
     <S.PageMenu justifyContent={"start"}>
      <S.InputCheck>
          <label>
            Typ av anläggning
          </label>
          <div>
            <input type="text" value={facilities[data?.facility_type || 0]}/>
          </div>
        </S.InputCheck>
        <S.InputCheck>
          <label>
            Produkt
          </label>
          <div>
            <input type="text" value={product && product.name}/>
          </div>
        </S.InputCheck>
        <S.InputCheck>
          <label>
            Aktuell volym (liter)
          </label>
          <div>
            <input type="text" value={data?.facility_volume}/>
          </div>
        </S.InputCheck>
        <S.InputCheck>
          <label>
            Driftsvolym (liter)
          </label>
          <div>
            <input type="text" value={data?.facility_maxvolume}/>
          </div>
        </S.InputCheck>
        <S.InputCheck>
          <label>
            Installationsdatum
          </label>
          <div>
            <input type="text" value={data?.facility_installation_date}/>
          </div>
        </S.InputCheck>
     </S.PageMenu>
   </S.SubPage>
  )
}
export default FacilityPage