import { useState } from "react"
import useStore from "../stores"
import S from "./styled"

export const Login = () => {

  const login = useStore(state => state.login)
  const statusMessage = useStore(state => state.statusMessage)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginMessage, setLoginMessage] = useState('')


  const handleLogin = (e:React.MouseEvent) => {
    setLoginMessage(JSON.parse(localStorage.getItem('user') || '').user_display_name)
  }

  return (
    <S.LoginForm>
      <S.Input value={username} onChange={(e) => setUsername(e.currentTarget.value)} type="text" placeholder="Användarnamn"/>
      <S.Input value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password" placeholder="Lösenord"/>
      <S.Button onClick={(e) =>login(username, password)}>Logga in</S.Button>
      {statusMessage || ''}
    </S.LoginForm>
  )
}

export default Login