import axios from "axios"
import { useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import IFacility from "../interfaces/IFacility"
import IProduct from "../interfaces/IProduct"

export const useFacilities = () => {
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries('facility')
  }, [])
    
  const headers = {
    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('user') || '').token}`
  }

  return useQuery("facilities", async () => {

    
    let { data } = await axios.get("https://envirostripp.se/wp-json/envapp/v1/facilities",{headers})
    if(data.length === 0 || !data) window.location.href='/'
    return JSON.parse(data) as IFacility[]
  }, {
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })
}